<template>
  <div>
    <v-row>
      <v-col cols="12">
        <select-warehouse-model
          v-model="id_warehouse"
          :label="$t('labels.warehouse')"
          @change="onWarehouseChange"
          :single-line="false"
        ></select-warehouse-model>
      </v-col>

      <v-col cols="12">
        <input-qr-scan-model
          v-model="basket_code"
          ref="codeRef"
          :label="$t('labels.basket_code')"
          :disabled="!isDisabledBtn"
          @keyupEnter="getReceiptBasket"
        ></input-qr-scan-model>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="success"
          block
          :disabled="isDisabledBtn"
          @click="showConfirmDialog"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th role="columnheader" class="text-center">#</th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.sku") }}
                </th>
                <th role="columnheader" class="text-center">
                  {{ $t("labels.basket") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) in scanned_baskets"
                :key="`se_${item.id}`"
                class="text-center"
              >
                <td>{{ key + 1 }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.basket_code }}</td>
              </tr>
              <tr
                v-for="(item, key) in scanning_baskets"
                :key="`si_${item.id}`"
                class="text-center error--text"
              >
                <td>{{ key + 1 + scanned_baskets.length }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.basket_code }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="confirmDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h6">{{ $t("labels.confirm") }}</v-card-title>
        <v-card-text>
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td style="width: 75px">
                    {{ $t("labels.sku") }}
                  </td>
                  <td>
                    {{ scanned.sku }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t("labels.employee_receipt_1") }}
                  </td>
                  <td>
                    {{ scanned.employee_name }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("labels.time") }}</td>
                  <td>
                    {{ formatDateTime(scanned.created_at) }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("labels.basket") }}</td>
                  <td>
                    {{ scannedBasketCodes.join("; ") }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("labels.quantity") }}</td>
                  <td>
                    {{ scanned.quantity }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="error" @click="hideConfirmDialog">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="success" @click="createCommand">
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { formatDateTime } from "@/libs/helpers";

export default {
  name: "QCReceiptBasket",
  components: {},
  data: () => ({
    basket_code: null,
    isLoading: false,
    id_warehouse: null,
    scanned_baskets: [],
    scanning_baskets: [],
    confirmDialog: false,
    scanned: {},
  }),
  computed: {
    isDisabledBtn() {
      return (
        this.scanned_baskets.length === 0 || this.scanning_baskets.length > 0
      );
    },
    scannedBasketCodes() {
      return [...this.scanned_baskets].map((s) => s.basket_code);
    },
  },
  mounted() {},
  methods: {
    formatDateTime,
    showConfirmDialog() {
      this.confirmDialog = true;
    },
    hideConfirmDialog() {
      this.confirmDialog = false;
    },
    async getReceiptBasket() {
      if (!this.basket_code) {
        return false;
      }

      if (!this.isDisabledBtn) {
        this.basket_code = null;
        return false;
      }

      const checkBasket = this.scanned_baskets.find(
        (c) => c.basket_code == this.basket_code
      );
      if (checkBasket) {
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(
          this.$t("messages.scanned_basket_code", { code: this.basket_code })
        );
        this.basket_code = null;
        return false;
      }

      if (this.scanning_baskets.length > 0) {
        const checkScanningBasket = this.scanning_baskets.find(
          (c) => c.basket_code == this.basket_code
        );
        if (!checkScanningBasket) {
          const sBaskets = this.scanning_baskets.map((sb) => sb.basket_code);
          document.getElementById("error_sound_player").play();
          this.$vToastify.error(
            this.$t("messages.scan_basket_same_tracking", {
              codes: sBaskets.join(", "),
            })
          );
          this.basket_code = null;
          return false;
        }
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/quality-control/v1/get-basket",
          {
            basket_code: this.basket_code,
            id_warehouse: this.id_warehouse,
          }
        );
        this.scanned = { ...data };
        this.scanned_baskets.push({
          // ...data.item,
          id_basket: data.id_basket,
          basket_code: data.basket_code,
          id_goods: data.id_goods,
          sku: data.sku,
          current_id: data.current_id,
          current_type: data.current_type,
        });
        const scanning_baskets = [...data.items].filter(
          (i) => !this.scanned_baskets.find((j) => j.id_basket === i.id_basket)
        );
        this.scanning_baskets = [...scanning_baskets];
        this.isLoading = false;
        this.basket_code = null;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.basket_code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },

    onWarehouseChange() {
      this.$refs.codeRef.focusInput();
    },

    async createCommand() {
      if (this.isDisabledBtn) {
        this.$vToastify.error(this.$t("messages.can_not_process_stowing"));
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/quality-control/v1/create-command",
          {
            scanned_baskets: this.scanned_baskets,
            id_warehouse: this.id_warehouse,
          }
        );
        this.$emit("onCreated", data);
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.basket_code = null;
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
